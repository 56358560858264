import { html, css, LitElement, PropertyValues } from 'lit';
import { customElement, state } from 'lit/decorators.js';

import type { ClientAuthHydrationData } from '../server-shared/client-auth.d.ts';

import poppies_bg from 'url:./assets/poppies-bg.jpeg';

@customElement('client-auth-login-landing')
export class AppClientAuthLoginLanding extends LitElement {
    static styles = css`
        :host {
            width: 100%;
            height: 100%;
            color: var(--hello-world-text-color, black);
        }

        .container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;

            background-size: cover;
        }

        .frame {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            z-index: 20;

            padding: 3rem 2rem;
            background-color: rgba(255, 255, 255, 0.8);
            border: 1px solid black;
            border-radius: 1rem;
        }

        h1 {
            font-family: "Wulkan Display Bold";
        }

        .sign-in-btn {
            background-color: white;
            border: 1px solid black;
            border-radius: 0.5rem;
            cursor: pointer;

            display: flex;
            flex-doirection: row;
            align-items: center;
            justify-content: center;

            padding: 0.5rem 1rem;
            margin-bottom: 0.5rem;
        }

        .sign-in-btn:hover{
            background-color: #f0f0f0;
        }

        .sign-in-btn-icon {
            width: 2rem;
            height: 2rem;
            margin-right: 0.5rem;

            display: flex;
            flex-doirection: row;
            align-items: center;
            justify-content: center;
        }

        .sign-in-btn-contents {
            width: 14rem;
            font-family: "Wulkan Display Bold";
            font-size: 16pt;
            color: black;
            text-align: center;
        }

        a {
            text-decoration: none;
        }
    `;

    @state() authenticated: boolean = false;
    @state() username?: string;
    @state() email?: string;

    firstUpdated() {
        // @ts-ignore
        const data = window.__DATA__ as ClientAuthHydrationData;
        if(data.authenticated){
            this.authenticated = data.authenticated;
            this.username = data.username;
            this.email = data.email;
        }
    }

    render() {
        return html`
        ${!this.authenticated ? 
            html`<div class="container">
                <div class="frame">

                <h1>Asunder Authentication</h1>
                <p>Please log in via your chosen provider.</p>

                <a href="/auth/google">
                <button class="sign-in-btn">
                    <div class="sign-in-btn-icon">
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" xmlns:xlink="http://www.w3.org/1999/xlink" style="display: block;">
                        <path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path>
                        <path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path>
                        <path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path>
                        <path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path>
                        <path fill="none" d="M0 0h48v48H0z"></path>
                    </svg>
                    </div>
                    <span class="sign-in-btn-contents">Sign in with Google</span>
                </button>
                </a>

                <a href="/auth/discord">
                <button class="sign-in-btn">
                    <div class="sign-in-btn-icon">
                    <svg id="Discord-Logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 126.644 96">
                        <defs><style>.cls-1{fill:#5865f2;}</style></defs>
                        <path id="Discord-Symbol-Blurple" class="cls-1" d="M81.15,0c-1.2376,2.1973-2.3489,4.4704-3.3591,6.794-9.5975-1.4396-19.3718-1.4396-28.9945,0-.985-2.3236-2.1216-4.5967-3.3591-6.794-9.0166,1.5407-17.8059,4.2431-26.1405,8.0568C2.779,32.5304-1.6914,56.3725.5312,79.8863c9.6732,7.1476,20.5083,12.603,32.0505,16.0884,2.6014-3.4854,4.8998-7.1981,6.8698-11.0623-3.738-1.3891-7.3497-3.1318-10.8098-5.1523.9092-.6567,1.7932-1.3386,2.6519-1.9953,20.281,9.547,43.7696,9.547,64.0758,0,.8587.7072,1.7427,1.3891,2.6519,1.9953-3.4601,2.0457-7.0718,3.7632-10.835,5.1776,1.97,3.8642,4.2683,7.5769,6.8698,11.0623,11.5419-3.4854,22.3769-8.9156,32.0509-16.0631,2.626-27.2771-4.496-50.9172-18.817-71.8548C98.9811,4.2684,90.1918,1.5659,81.1752.0505l-.0252-.0505ZM42.2802,65.4144c-6.2383,0-11.4159-5.6575-11.4159-12.6535s4.9755-12.6788,11.3907-12.6788,11.5169,5.708,11.4159,12.6788c-.101,6.9708-5.026,12.6535-11.3907,12.6535ZM84.3576,65.4144c-6.2637,0-11.3907-5.6575-11.3907-12.6535s4.9755-12.6788,11.3907-12.6788,11.4917,5.708,11.3906,12.6788c-.101,6.9708-5.026,12.6535-11.3906,12.6535Z"/>
                    </svg>
                    </div>
                    <span class="sign-in-btn-contents">Sign in with Discord</span>
                </button>
                </a>

                <a href="/auth/sso">
                <button class="sign-in-btn">
                <div class="sign-in-btn-state"></div>
                    <div class="sign-in-btn-icon">
                    <svg version="1.1" 
                        id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                        viewBox="0 0 1000 1000" style="enable-background:new 0 0 1000 1000;" xml:space="preserve">
                    <g>
                        <g><path d="M690.08,469.88H309.92c-23.34,0-42.26,18.92-42.26,42.26v268.59c0,23.34,18.92,42.26,42.26,42.26h380.16c23.34,0,42.26-18.92,42.26-42.26V512.14C732.35,488.8,713.42,469.88,690.08,469.88z M528.7,652.31v60.21c0,14.96-12.12,27.08-27.08,27.08c-14.96,0-27.08-12.12-27.08-27.08v-60.21c-13.06-8.74-21.66-23.63-21.66-40.53c0-26.92,21.82-48.74,48.74-48.74c26.92,0,48.74,21.82,48.74,48.74C550.37,628.68,541.77,643.56,528.7,652.31z"/></g>
                        <g><path d="M675.62,436.43h-76.1c-4.41,0-7.99-3.58-7.99-7.99v-66.12c0-50.69-40.48-92.75-91.16-93.24c-51.18-0.5-92.98,41-92.98,92.07v67.3c0,4.41-3.58,7.99-7.99,7.99h-76.1c-4.41,0-7.99-3.58-7.99-7.99v-65.7c0-101.23,82.82-185.69,184.05-185.74c101.58-0.05,184.24,82.58,184.24,184.14v67.3C683.6,432.85,680.03,436.43,675.62,436.43z"/></g>
                    </g>
                    </svg>
                    </div>

                    <span class="sign-in-btn-contents">Sign in with SSO</span>
                </button>
                </a>

            </div>
            </div>` :
        
            html`
            <div class='container'>
            <div class='frame'>
                <h1>Welcome, ${this.username}!</h1>
                <a href="/auth/logout">
                <button class="sign-in-btn">
                    <span class="sign-in-btn-contents">Logout</span>
                </button>
                </a>
            </div>
            </div>`
        }`;
    }
}