import { html, css, LitElement, PropertyValues } from 'lit';
import { customElement, state } from 'lit/decorators.js';

import type { ClientAuthLoginSSOHydrationData } from '../server-shared/client-auth.d.ts';

@customElement('client-auth-login-sso')
export class AppClientAuthLoginSSO extends LitElement {
    static styles = css`
        :host {
            display: block;
            padding: 16px;
            color: var(--hello-world-text-color, black);
        }

        h1 {
            font-family: "Wulkan Display Bold";
        }
    `;

    @state() email: string = '';
    @state() csrf_token: string = '';
    @state() error_message: string = '';

    firstUpdated() {
        // @ts-ignore
        const data = window.__DATA__ as ClientAuthLoginSSOHydrationData;
        this.csrf_token = data.csrf_token;
        this.error_message = data.error;
    }

    render() {
        return html`
        <h1>Login credentials</h1>
        <form action="/auth/sso" method="POST">
            <input type="hidden" name="_csrf" value="${this.csrf_token}" />
            <input type="text" name="email" placeholder="Enter your email" required />
            <input type="text" name="password" placeholder="Enter your password" required />
            <button type="submit">Submit</button>
        </form>
        ${this.error_message.length > 0 ? html`<p style="color:red;">${this.error_message}</p>` : ''}`;
    }
}